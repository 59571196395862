import React from "react";
import styled from "styled-components";

const StyledCommunity = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 650px;

  @media (min-width: 1280px) {
    margin-top: 650px;
  }
  
  @media (max-width: 768px) {
     margin-top: 360px;
    }
  
    @media (max-width: 480px) {
     margin-top: 150px;
    }
    

    
  .overlap-group {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column; 
      align-items: center;
    }

    @media (max-width: 480px) {
      flex-direction: column; 
      align-items: center;
      
    }

    .textwrapper {
      @media (max-width: 480px) {
    width: 300px;
    height: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }
      .connect-build, .title, .subtitle, .bodytitle {
        color: black;
        margin-bottom: 10px;
      }

      .connect-build {
        font-family: "Unbounded-Regular";
        font-size: 24px;
        font-weight: 400;
      }

      .title {
        font-family: "Unbounded-Bold";
        font-size: 72px;
        font-weight: 700;
      }

      .subtitle {
        font-family: "Unbounded-Regular";
        font-size: 38px;
        font-weight: 400;
      }

      .bodytitle {
        font-family: "Unbounded-Regular";
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        margin-top: 100px;
      }

      @media (max-width: 1280px) {
        .title {
          font-size: 48px;
          margin-top: 15px;
        }

        .subtitle {
          font-size: 24px;
          margin-top: 4px;
        }

        .bodytitle {
          font-size: 14px;
          margin-top: 60px;
        }
      }

      @media (max-width: 768px) {
        .title {
          font-size: 72px;
          margin-top: 15px;
        }

        .subtitle {
          font-size: 24px;
          margin-top: 15px;
        }

        .bodytitle {
          font-size: 14px;
          margin-top: 60px;
        }
      }

      @media (max-width: 480px) {

        .connect-build {
        font-size: 16px;
      }
        .title {
          font-size: 36px;
          margin-top: 10px;
        }

        .subtitle {
          font-size: 20px;
          margin-top: 5px;
        }

        .bodytitle {
          line-height: 18px;
          font-size: 9px;
          margin-top: 50px;
        }
      }
    }

    .communityphone {
      object-fit: cover;
      margin-right: 10px;
      width: 668px;

      @media (max-width: 1280px) {
        width: 36%;
        margin-right: 0;
        margin-top: 180px;
      }

      @media (max-width: 768px) {
        width: 80%;
        margin-right: 0;
        margin-top: 180px;
      }

      @media (max-width: 480px) {
        width: 92%;
        margin-top: 20px;
      }

      @media (min-width: 1280px) { /* 1280 화면에서 이미지가 왼쪽에 보이도록 추가 */
        order: -1; 
      }
    }
  }
`;

const Community = () => {
  return (
    <StyledCommunity>
      <div className="overlap-group">
        <div className="textwrapper">
          <div className="connect-build">CONNECT &amp; BUILD</div>
          <div className="title">COMMUNITY</div>
          <div className="subtitle">WITH YOUR SUPER FANS</div>
          <p className="bodytitle">
            All Artists Can Build Their Own Fan Communities On <br /> 
            Cookiedog And Have A Differentiated <br />
            Communication Experience.
          </p>
        </div>
        <img className="communityphone" alt="Communityphone" src="/assets/communityphone.png" />
      </div>
    </StyledCommunity>
  );
};

export default Community;
