import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
    background-color: white;
    height: 80px;
    bottom: 0;
    width: 100%;

  & .overlap-group {
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    height: 100%; 
    width: 100%; 
    padding: 0 40px; 
  }

  & .logo {
    height: 40px;
    width: auto;
    max-height: 100%;
    object-fit: cover;
  }

  & .icons {
    display: flex;
  }

  & .icon {
    height: 36px;
    object-fit: contain;
    width: 50px;
    margin-left: 5px;
  }


  @media (max-width: 768px) {
    height: 70px;
    & .overlap-group {
      padding: 0 30px; 
    }

    & .logo {
      height: 30px; 
      width: auto; 
    }

    & .icon {
      height: 30px;
      width: 30px;
      margin-left: 10px; 
    }
  }

  @media (max-width: 480px) {
      height: 55px;

    & .overlap-group {
      padding: 0 20px; 
    }

    & .logo {
      height: 25px;
      width: auto; 
    }

    & .icon {
      height: 25px;
      width: 25px;
      margin-left: 10px; 
    }
  }
`;

const Footer = () => {
  const handleInstagramClick = () => {
    window.open('https://www.instagram.com/cookiedog.xyz?igshid=a3ZscTZldmRjaXo3', '_blank'); // 새 탭에서 링크 열기
  };

  const handleDiscordClick = () => {
    window.open('https://discord.gg/jf2d2fVQ', '_blank'); // 새 탭에서 디스코드 링크 열기
  };

  return (
    <StyledFooter>
        <div className="overlap-group">
          <img className="logo" alt="Logo" src="/assets/logo-footer.png" onClick={handleInstagramClick} /> 
          <div className="icons">
            <img className="icon instagram" alt="Instagram" src="/assets/instagram.png" onClick={handleInstagramClick} />
            <img className="icon line" alt="Line" src="/assets/line.png" />
            <img className="icon discord" alt="Discord" src="/assets/discord.png" onClick={handleDiscordClick} />
          </div>
        </div>
    </StyledFooter>
  );
};

export default Footer;