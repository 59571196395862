import styled from 'styled-components';

const DownloadButton = ({ logoSrc, store, src }) => {
  return (
    <Container href={src}>
      <ImgWrapper src={logoSrc} alt="logo" />
      <Text>{store}</Text>
    </Container>
  );
};

const Container = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 1px solid #000;
  width: 100%;
  height: 3rem;
  background-color: #fff;
  border-radius: 0.75rem;
  padding: 8px 32px;
`;

const Text = styled.p`
  white-space: nowrap;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  word-break: keep-all;
  height: 18px;
`

const ImgWrapper = styled.img`
  //   position: absolute;
  //   left: 1rem;
  //   top: 15px;
    width: 16px;
`;


export default DownloadButton;
