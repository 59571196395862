import React from "react";
import styled from "styled-components";

const StyledFanBackground = styled.div`
  background: linear-gradient(180deg, rgb(240, 249, 137) 0%, rgb(252, 255, 221) 100%);
  border-radius: 25px;
  height: 298px;
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
  
  @media (max-width: 768px) {
  margin-top: 0;
    width: 95%;
  }

`;
const StyledTitleF = styled.div`
   color: black;
  font-family: "Unbounded-Bold";
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const StyledSubTitleF = styled.div`
  color: black;
  font-family: "Unbounded-Regular";
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const StyledClick2 = styled.button`
  background-color: black;
  color: white;
  font-family: "Unbounded-Regular";
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  height: 40px;
  width: 177px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
`;

const FanCard = () => {
  return (
    <StyledFanBackground>
        <StyledTitleF>
          Are You <br />a Fan?
        </StyledTitleF>
        <StyledSubTitleF>Join Our Waitlist</StyledSubTitleF>
          <StyledClick2>Send</StyledClick2>
    </StyledFanBackground>
  );
};

export default FanCard;
