import React from "react";
import styled from "styled-components";
import DownloadButtonContainer from "../Download/DownloadButtonContainer";

const StyledQRBackground = styled.div`
  background-color: white;
  border-radius: 20px;
  width: 100%; 
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  margin-bottom: 200px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledDownloadCookiedog = styled.p`
  color: black;
  font-family: "Unbounded-Bold";
  font-size: 24px;
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px; 
  }
`;

const StyledQRImage = styled.img`
  background-color: transparent;
  border: 2px solid grey;
  border-radius: 8px;
  padding: 4px;
  height: 72px;
  width: 73px;
`;

const StyledDownloadButton = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`

const QRCard = () => {
  return (
    <>
      <StyledQRBackground>
        <StyledDownloadCookiedog>{`Download Cookiedog \n App To Get Started`}</StyledDownloadCookiedog>
        <StyledQRImage alt="Qr" src="/assets/QR.png" />
      </StyledQRBackground>
      <StyledDownloadButton>
        <DownloadButtonContainer />
      </StyledDownloadButton>
    </>
  );
};

export default QRCard;

