import React from "react";
import styled, { css } from "styled-components";
import Introduction from "./Introduction";

const sizes = {
  desktop: 1660,
  laptop: 1440,
  tablet: 768,
  phone: 480,
};

// 사이즈에 따라 미디어 쿼리 
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const OverlapWrapper = styled.div`
  width: 100%;
  background: transparent;
  overflow-x: hidden;
  position: relative;

  @media (max-width: 768px) {
    margin-bottom: 60px;
  }

  @media (max-width: 480px) {
    margin-bottom: 20px;
  }

`

const StyledImage = styled.img`
  object-fit: cover;
  position: absolute;
`;

const LeftCookie = styled(StyledImage)`
  height: 262px;
  width: 260px;
  left: 120px;
  top: 550px;

  ${media.desktop`
    height: 200px;
    width: 200px;
    left: 100px;
    top: 600px;
  `}

  ${media.laptop`
    height: 200px;
    width: 200px;
    left: 40px;
    top: 550px;
  `}

  ${media.tablet`
    display: none;
  `}
`;

const RightCookie = styled(StyledImage)`
  width: 400px;
  right: -60px;
  top: 20px;
  object-fit: contain;

  ${media.desktop`
    width: 360px;
    right: -40px;
    top: 20px;
  `}

  ${media.laptop`
    height: 440px;
    width: 340px;
    right: -50px;
    top: 40px;
  `}

  ${media.tablet`
    display: none;
  `}
`;

const VolumeAi1 = styled(StyledImage)`
  height: 216px;
  width: 512px;
  top: 760px;

  ${media.desktop`
    height: 276px;
    width: 572px;
    top: 730px;
    object-fit: contain;
  `}

  ${media.laptop`
    height: 216px;
    width: 512px;
    top: 760px;
  `}

  ${media.tablet`
    height: 162px;
    width: 396px;
    top: 640px;
  `}

  ${media.phone`
    height: 90px;
    width: 132px;
    top: 410px;
    left: 0px;
  `}
`;

const VolumeAi2 = styled(StyledImage)`
  height: 192px;
  width: 476px;
  top: 790px;
  right: 0px;

  ${media.desktop`
    height: 232px;
    width: 516px;
    top: 770px;
    right: 0px;
    object-fit: contain;
  `}

  ${media.laptop`
    height: 192px;
    width: 476px;
    top: 790px;
    right: 0px;
  `}

  ${media.tablet`
    height: 144px;
    width: 370px;
    top: 660px;
    right: 0px;
  `}

  ${media.phone`
    height: 90px;
    width: 134px;
    top: 410px;
    right: 0px;
  `}
`;

const IntroBackground = ({ scrollToWaitlist }) => {
  return (
    <OverlapWrapper>
      <Introduction scrollToWaitlist={scrollToWaitlist} />
      <VolumeAi1 alt="Volume ai" src="/assets/volume-1.png" />
      <VolumeAi2 alt="Volume ai" src="/assets/volume-2.png" />
      <LeftCookie alt="Left cookie" src="/assets/left-cookie.png" />
      <RightCookie alt="Right cookie" src="/assets/right-cookie.png" />
    </OverlapWrapper>
  );
};

export default IntroBackground;
