import React from 'react';
import styled from 'styled-components';
import SoundPack from './MusicPackage/SoundPack';
import Community from '../Descriptions/Community';
import Withdraw from '../Descriptions/Withdraw';

const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`;

const StyledBodyBG = styled.img`
  width: 100%;
  height: 101%;
  position: absolute;
  top: 0;
  left: 0; 
  z-index: -1; 

  @media (max-width: 768px) {
    content: url('/assets/bodyBG-t.png');
  }
  @media (max-width: 480px) {
    content: url('/assets/bodyBG-m2.png');
  }
`;

const Description = () => {
  return (
    <StyledDescription>
      <StyledBodyBG alt="Body BG" src="/assets/bodyBG.png" />
      <SoundPack />
      <Community />
      <Withdraw />
    </StyledDescription>
  );
};

export default Description;
