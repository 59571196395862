import React, { useState } from "react";
import styled from "styled-components";

const StyledSoundPack = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .soundpackBackground {
    .tabs {
      display: flex;
      justify-content: center;
      margin-top: 140px;
      gap: 60px;

      @media (min-width: 1440px) {
        font-size: 24px;
        gap: 80px;
      }

      @media (max-width: 768px) {
        margin-top: 150px;
        gap: 40px;
      }

      @media (max-width: 480px) {
        margin-top: 70px;
      }
    }

    .tab {
      color: black;
      font-family: "Unbounded-Regular";
      font-size: 20px;
      font-weight: 400;
      padding-bottom: 5px;
      cursor: pointer;

      &.active {
        border-bottom: 3px solid black;
      }

      @media (min-width: 1440px) {
        font-size: 24px;
      }

      @media (max-width: 768px) {
        font-size: 22px;
      }

      @media (max-width: 480px) {
        font-size: 16px;
      }
    }

    .content {
      font-family: "Unbounded-Regular";
      display: flex;
      justify-content: center;
      align-items: end;
      width: 100%;
      margin: 0 auto;
      margin-top: 20px;

      ${({activeTab}) => {
        if (activeTab === 'CONNECT') {
          return 'gap: 40px;';
        }
      }}

      @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
      }

      @media (max-width: 480px) {
        width: 300px;
        height: auto;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    .text-content {
     
      .release {
        font-family: "Unbounded-Regular";
        font-size: 24px;
        font-weight: 400;
        margin-top: 80px;

        @media (min-width: 1440px) {
        font-size: 28px;
      }
        
        @media (max-width: 768px) {
          font-size: 24px;
          margin-top: 95px;
        }

        @media (max-width: 480px) {
          font-size: 16px;
          margin-top: 80px;
        }
      }

      .title {
        font-family: "Unbounded-Bold";
        font-size: 72px;
        font-weight: 700;
        margin-top: 30px;

        @media (max-width: 1280px) {
          font-size: 48px;
          margin-top: 15px;
        }

        @media (max-width: 768px) {
          font-size: 72px;
          margin-top: 15px;
        }

        @media (max-width: 480px) {
          font-size: 36px;
          margin-top: 10px;
        }
      }

      .subtitle {
        font-family: "Unbounded-Regular";
        font-size: 38px;
        font-weight: 400;

        @media (max-width: 1280px) {
          font-size: 24px;
          margin-top: 4px;
        }

        @media (max-width: 768px) {
          font-size: 24px;
          margin-top: 15px;
        }

        @media (max-width: 480px) {
          font-size: 20px;
          margin-top: 5px;
        }
      }

      .bodytitle {
        font-family: "Unbounded-Regular";
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        margin-top: 100px;


        @media (max-width: 1280px) {
          font-size: 14px;
          margin-top: 60px;
        }

        @media (max-width: 768px) {
          font-size: 14px;
          margin-top: 60px;
        }

        @media (max-width: 480px) {
          line-height: 18px;
          width: 300px;
          font-size: 9px;
          margin-top: 50px;
        }
      }
    }

    ${({activeTab}) => {
      if (activeTab === 'CONNECT') {
        return `
          .sound-image {
            height: 340px;
            object-fit: contain;

            @media (max-width: 1280px) {
              width: 36%;
            }

            @media (max-width: 768px) {
              width: 60%;
            }

            @media (max-width: 480px) {
              width: 50%;
              height: auto;
              margin-top: 0px;
            }
          }
        `;
      } else {
        return `
          .sound-image {
            height: 460px;
            object-fit: contain;

            @media (max-width: 1280px) {
              width: 36%;
            }

            @media (max-width: 768px) {
              width: 60%;
            }

            @media (max-width: 480px) {
              width: 84%;
              height: auto;
              margin-top: 0px;
            }
          }
        `;
      }
    }}
  }
`;

const SoundPack = () => {
  const [activeTab, setActiveTab] = useState("SOUND PACK");

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  const content = {
    "SOUND PACK": {
      title: "SOUND PACK",
      subtitle: "FOR YOUR SUPER FANS",
      bodytitle: (
        <>
          Sound Pack Is A Digital Music Album For Your Super Fans.<br />
          This Is A Great Way To Connect With Your Super Fans<br />
          and Reach New Ones.
        </>
      ),
      imageSrc: "/assets/sound-image.png",
      release: "RELEASE"
    },
    "CONNECT": {
      title: "CONNECT",
      subtitle: "To Your Super Fans",
      bodytitle: (
        <>
          Connect is Collectible Digital Cards - Photo, Shorts Video, Voice Mail.<br />
          This is Key to access Artist Room.
        </>
      ),
      imageSrc: "/assets/connect.png",
      release: "PROVIDE"
    }
  };

  return (
    <StyledSoundPack activeTab={activeTab}>
      <div className="soundpackBackground">
        <div className="tabs">
          <div className={`tab ${activeTab === "SOUND PACK" ? "active" : ""}`} onClick={() => handleClick("SOUND PACK")}>
            SOUND PACK
          </div>
          <div className={`tab ${activeTab === "CONNECT" ? "active" : ""}`} onClick={() => handleClick("CONNECT")}>
            CONNECT
          </div>
        </div>
        <div className="content">
          <div className="text-content">
            <div className="release">{content[activeTab].release}</div>
            <div className="title">{content[activeTab].title}</div>
            <div className="subtitle">{content[activeTab].subtitle}</div>
            <p className="bodytitle">{content[activeTab].bodytitle}</p>
          </div>
          <img className="sound-image" alt="SoundImage" src={content[activeTab].imageSrc} />
        </div>
      </div>
    </StyledSoundPack>
  );
}

export default SoundPack;
