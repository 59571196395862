import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.div`
  & .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    height: 130px;
    width: 90%;
    left: 50%;
    top: 0;
    position: fixed;
    z-index: 999;
    transform: translateX(-50%);

    @media (max-width: 768px) {
      height: 100px;
    }

    @media (max-width: 480px) {
      height: 80px;
    }
  }

  & .logoimage {
    height: 50px;
    width: auto;

    @media (max-width: 768px) {
      height: 40px;
    }

    @media (max-width: 480px) {
      height: 30px;
    }
  }
`;

const StudioButton = styled.a`
  font-family: Unbounded-Regular;
  padding: 18px 34px;
  border-radius: 9999px;
  background-color: #befffb;

  &::after {
    content: 'COOKIEDOG STUDIO';
  }

  @media (max-width: 480px) {
    padding: 13px 20px;

    &::after {
      content: 'GO TO STUDIO';
    }
  }
`;

const Header = () => {
  return (
    <StyledHeader>
      <div className="navbar">
        <img className="logoimage" alt="logo" src="/assets/logo.png" />
        <StudioButton href="https://studio.cookiedog.xyz" target="_blank" />
      </div>
    </StyledHeader>
  );
};

export default Header;
