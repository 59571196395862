import React from "react";
import styled from "styled-components";

const StyledMusicianBackground = styled.div`
  background: linear-gradient(
    180deg,
    rgb(76, 127, 255) 0%,
    rgb(210, 225, 255) 100%
  );
  border-radius: 25px;
  height: 298px;
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 90px;

  @media (max-width: 768px) {
    width: 95%;
    margin-top: 40px;
  }
`;

const StyledTitleM = styled.div`
  color: black;
  font-family: "Unbounded-Bold";
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const StyledSubTitleM = styled.div`
  color: black;
  font-family: "Unbounded-Regular";
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const StyledClick = styled.a`
  background-color: white;
  color: black;
  font-family: "Unbounded-Regular";
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 177px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d9d9d9;
  }
`;

const MusicianCard = () => {
  return (
    <>
      <StyledMusicianBackground>
        <StyledTitleM>
          Are You <br />a Musician?
        </StyledTitleM>
        <StyledSubTitleM>Join Our Waitlist</StyledSubTitleM>
        <StyledClick href="https://studio.cookiedog.xyz" target="_blank">
          GO TO STUDIO
        </StyledClick>
      </StyledMusicianBackground>
    </>
  );
};

export default MusicianCard;
