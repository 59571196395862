import React, { useRef } from 'react';
import styled from "styled-components";
import Description from '../components/Descriptions/Description';
import Discovers from '../components/Discover/Discovers';
import Footer from '../components/Footer';
import Header from '../components/Header';
import IntroBackground from '../components/IntroBackground';
import Waitlist from '../components/Waitlist';


function Home() {

  const waitlistRef = useRef(null);

  const scrollToWaitlist = () => {
    waitlistRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const StyledHome = styled.div`

  @media (max-width: 480px) {
  height: auto;
  overflow-x: hidden;
}
`;
  

  return (
    <StyledHome>
      <Header/>
      <IntroBackground scrollToWaitlist={scrollToWaitlist} />
      <Discovers/>
      <Description/>
      <Waitlist ref={waitlistRef} />
      <Footer/>
      </StyledHome>
  );
}

export default Home;