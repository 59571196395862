import React from "react";
import styled from "styled-components";
import DownloadButtonContainer from "./Download/DownloadButtonContainer";

const StyledIntroduction = styled.div`
    background-color: transparent;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
    margin-top: 160px;
    z-index: 10; /* IntroBackground보다 앞 레이어여야 됨 */
    position: relative; 
    
  & .title {
    color: #ffffff;
    font-family: "Unbounded-Bold";
    font-size: 80px;
    font-weight: 700;
    line-height: 82px;
  }

  & .bodytitle {
    color: white;
    font-family: "Unbounded-Light";
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    text-align: center;
    margin: 30px;
  }

  & .download {
    border: 1px solid #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 4px;

    > div {
    color: #fff;
    font-family: Unbounded-Bold;
    font-size: 14px;
    text-align: start;
      padding: 0 20px;
    }

    & img {
      width: 82px;
    }
    
    @media (max-width: 768px) {
      display: none;
    }
  }

  & .store {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  & .button:hover {
    background-color: white; 
    color: black;
    border-color: black;
  }

  & .image-phone {
    background-color: transparent;
    object-fit: contain;
    margin-top: 20px;
    width: auto;  
    height: auto; 
  }

    
  @media (max-width: 768px) {
    margin-top: 150px;
    & .title {
      font-size: 72px; 
      line-height: 70px; 
    }

    & .bodytitle {
      font-size: 16px; 
      line-height: 22px; 
      margin: 20px; 
    }

    & .button {
      width: 250px; 
      height: 50px; 
      font-size: 16px;
    }

    & .image-phone {
      width: 70%; 
      height: auto; 
      margin-top: 10px; 
    }
  }
  @media (max-width: 480px) {
    margin-top: 110px;
    /* overflow-y: hidden; */

    & .title {
      font-size: 38px; 
      line-height: 38px;
    }

    & .bodytitle {
      font-size: 10px; 
      line-height: 20px; 
      margin: 15px; 
    }

  
    & .button {
      width: 170px;
      height: 35px;
      font-size: 12px;
      border: 1px solid white;
    }

    & .image-phone {
      width: 80%; 
      height: auto; 
      margin-top: 5px; 
    }
  }
`;

const Introduction = ({ scrollToWaitlist }) => {
  return (
    <StyledIntroduction>
      <div className="title">
        MUSIC
        <br />
        FOR
        <br />
        SUPER FAN
      </div>
      <div className="bodytitle">
        MUSIC EXPRESSES THAT WHICH CANNOT BE SAID AND
        <br />
        ON WHICH IT IS IMPOSSIBLE TO BE SILENT.
      </div>
      <div className="download">
        <div>
          <p>Download</p>
          <p>CookieDOG</p>
        </div>
        <img src="/assets/QR.png" alt="CookieDOG download QR" />
      </div>
      <div className="store">
        <DownloadButtonContainer />
      </div>
      <div className="image-phone">
        <img className="imagephone" alt="ImagePhone" src="/assets/imagephone.png" />
      </div>
    </StyledIntroduction>
  );
};

export default Introduction;
