import React from "react";
import styled from "styled-components";
import MusicianCard from "./Waitlist/MusicianCard";
import FanCard from "./Waitlist/FanCard";
import QRCard from "./Waitlist/QRCard";

const StyledWaitlist = styled.div`
  background-color: transparent;
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 30px;
  width: 100%;
  
  @media (min-width: 1440px) {
    gap: 50px;
    padding: 50px;
  }

  @media (max-width: 768px) {
    gap: 20px;
    padding: 20px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
    padding: 20px; 
  }
`;

const StyledFooterAi = styled.img`
  object-fit: contain;
  width: 100%;
  max-width: 1900px;
  margin-bottom: 50px;

`;

const Waitlist = React.forwardRef((props, ref) => (
  <StyledWaitlist ref={ref}>
    <StyledCardsContainer>
      <MusicianCard />
      <FanCard />
    </StyledCardsContainer>
    <QRCard />
    <StyledFooterAi alt="Footer ai" src="/assets/footer-ai.png" />
  </StyledWaitlist>
));

export default Waitlist;

