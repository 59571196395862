import React from "react";
import styled from "styled-components";

const StyledWithdrawBG = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 300px;

  @media (max-width: 1440px) {
    margin-top: 200px;
  }
  
  @media (max-width: 768px) {
    height: auto;
    margin-top: 120px;
    margin-bottom: 100px;
  }

  @media (max-width: 480px) {
    height: auto;
    margin-top: 50px;
    margin-bottom: 40px;
  }
`;

const StyledOverlapGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1070px;
  height: auto;
  margin-top: 98px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 480px; 
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 480px;
  }
`;

const StyledMoneyimage = styled.img`
  width: 678px;
  height: 791px;
  object-fit: contain;

  @media (max-width: 1280px) {
    width: 320px;
    height: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    width: 78%;
    height: auto;
    margin-right: 0;
    margin-top: 30px;
  }
`;

const StyledTextwrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 500px;
  height: 394px;
  
  @media (max-width: 1280px) {
    width: auto;
  }

  @media (max-width: 480px) {
    width: 300px;
    height: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const StyledFast = styled.div`
  color: black;
  font-family: "Unbounded-Regular";
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const StyledTitle = styled.div`
  color: black;
  font-family: "Unbounded-Bold";
  font-size: 72px;
  font-weight: 700;
  margin-top: 30px;

  @media (max-width: 1280px) {
    font-size: 48px;
    margin-top: 15px;
  }

  @media (max-width: 768px) {
    font-size: 72px;
    margin-top: 15px;
  }

  @media (max-width: 480px) {
    font-size: 36px;
    margin-top: 10px;
  }
`;

const StyledSubTitle = styled.div`
  color: black;
  font-family: "Unbounded-Regular";
  font-size: 38px;
  font-weight: 400;

  @media (max-width: 1280px) {
    font-size: 24px;
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    margin-top: 15px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    margin-top: 5px;
  }
`;

const StyledBodyTitle = styled.p`
  color: black;
  font-family: "Unbounded-Regular";
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  margin-top: 100px;

  @media (max-width: 1280px) {
    font-size: 14px;
    margin-top: 60px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    margin-top: 60px;
  }

  @media (max-width: 480px) {
    line-height: 18px;
    font-size: 9px;
    margin-top: 50px;
  }
`;

const Withdraw = () => {
  return (
    <StyledWithdrawBG>
      <StyledOverlapGroup>
        <StyledTextwrapper>
          <StyledFast>FAST &amp; TRANSPARENT</StyledFast>
          <StyledTitle>WITHDRAW</StyledTitle>
          <StyledSubTitle>WITH ONE-CLICK</StyledSubTitle>
          <StyledBodyTitle>
            You Can Freely Withdraw Money At Any Time And <br />
            In Any Amount You Want. You Can Withdraw <br />
            In Fiat Or Crypto Currency.
          </StyledBodyTitle>
        </StyledTextwrapper>
        <StyledMoneyimage alt="MoneyImage" src="/assets/moneyimage.png" />
      </StyledOverlapGroup>
    </StyledWithdrawBG>
  );
};
 
export default Withdraw;
