import styled from 'styled-components';
import DownloadButton from './DownloadButton';

const DownloadButtonContainer = () => {
  return (
    <Container>
      <TitleWrapper>Download CookieDOG</TitleWrapper>
      <ButtonWrapper>
        <DownloadButton logoSrc='/assets/appleLogo.png' store="App Store" src="https://apps.apple.com/kr/app/%EC%BF%A0%ED%82%A4%EB%8F%85/id1669388382" />
        <DownloadButton logoSrc='/assets/playStoreLogo.png' store="Google Play" src="https://play.google.com/store/apps/details?id=com.cookiedog" />
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`

const TitleWrapper = styled.p`
  color: #fff;
  font-family: Unbounded-Regular;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 10px;
  font-weight: 500;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`

export default DownloadButtonContainer;
