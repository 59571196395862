import React from "react";
import styled from "styled-components";

const StyledDiscovers = styled.div`


  @media (max-width: 1280px) {
    display: none;
  }

  & .discoverBackground {
    background-color: black;
    height: 600px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 100px;


    @media (max-width: 1920px) {
      height: 400px;
      margin-top: 120px;
      margin-bottom: 180px;
    }

    @media (max-width: 768px) {
      height: 400px;
      margin-top: 80px;
      margin-bottom: 100px;
    }

    @media (max-width: 480px) {
      height: 1000px;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 80px;
      margin-bottom: 80px;

    }
  }

  & .box {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media (max-width: 768px) {
      height: 280px;
      width: 220px;
    }

    @media (max-width: 480px) {
      width: 100%; 
      height: 100%;
    }
  }

  & .soundpack {
    background-color: transparent;
    background-image: url(./assets/soundpack-box.svg);
    height: 360px;
    width: 288px;

    @media (max-width: 768px) {
      height: 280px;
      width: 220px;
    }

    @media (max-width: 480px) {
      width: 213px;
      height:267px
      margin-bottom: 20px;
    }
  }
  

  & .community {
    background-color: transparent;
    background-image: url(./assets/community-box.svg);
    height: 360px;
    width: 264px;

    @media (max-width: 768px) {
      height: 280px;
      width: 220px;
    }

    @media (max-width: 480px) {
      width: 213px;
      height:267px
      margin-bottom: 20px; 
    }
  }

  & .withdraw {
    background-color: transparent;
    background-image: url(./assets/withdraw-box.svg);
    height: 360px;
    width: 302px;

    @media (max-width: 768px) {
      height: 280px;
      width: 220px;
    }

    @media (max-width: 480px) {
      width: 213px;
      height:267px
      margin-bottom: 20px; 
    }
  }

  & .text-wrapper { 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .standard-text {
    color: black;
    font-family: "Unbounded-Regular";
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    order: 1;

    @media (max-width: 768px) {
      font-size: 12px;
    }

    @media (max-width: 480px) {
      font-size: 10px;
    }
  }

  & .title-text {
    color: black;
    font-family: "Unbounded-SemiBold";
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    order: 2;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
`;

const Discovers = () => {
  return (
    <StyledDiscovers>
      <div className="discoverBackground">
        <div className="soundpack box">
          <div className="text-wrapper">
            <div className="standard-text">RELEASE</div>
            <div className="title-text">SOUND PACK</div>
          </div>
        </div>
        <div className="community box">
          <div className="text-wrapper">
            <div className="standard-text">CONNECT &amp; BUILD</div>
            <div className="title-text">COMMUNITY</div>
          </div>
        </div>
        <div className="withdraw box">
          <div className="text-wrapper">
            <div className="standard-text">FAST &amp; TRANSPARENT</div>
            <div className="title-text">WITHDRAW</div>
          </div>
        </div>
      </div>
    </StyledDiscovers>
  );
};

export default Discovers;
